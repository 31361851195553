/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Home from "pages/Presentation";

// Material Kit 2 React routes
import routes from "routes";
import BusInstructions from "pages/LandingPages/Travel/sections/methods/bustravel";
import TaxiInstructions from "pages/LandingPages/Travel/sections/methods/taxi";
import SubwayInstructions from "pages/LandingPages/Travel/sections/methods/subway";
import L7HotelInfo from "pages/LandingPages/Travel/sections/methods/l7hotel";
import MercureAmbassadorHotelInfo from "pages/LandingPages/Travel/sections/methods/mercurehotel";
import SignInBasic from "pages/LandingPages/SignIn";
import SignUpBasic from "pages/LandingPages/SignUp";
import UserPageBasic from "pages/LandingPages/UserPage";
import ResetPassBasic from "pages/LandingPages/ResetPass";
import { ConfirmPage } from "pages/LandingPages/UserPage/registration/payment";
import ProposalSubmissions from "pages/LandingPages/Publications/proposal";
import EventRegistrationBasic from "pages/LandingPages/UserPage/registration/eventRegistration";
import SocialEvent1 from "pages/LandingPages/UserPage/registration/eventRegistration/events/socialevent1";
import SocialEvent2 from "pages/LandingPages/UserPage/registration/eventRegistration/events/socialevent2";
import { ConfirmEventsPaymentPage } from "pages/LandingPages/UserPage/registration/eventRegistration/events/payment";
import MKAlert from "components/MKAlert";
import SponsorCheckoutPage from "pages/LandingPages/UserPage/registration/sponsorRegistration";
import { ConfirmSponsorPage } from "pages/LandingPages/UserPage/registration/sponsorRegistration/confirm";
import IndividualKeyNoteTeam from "pages/LandingPages/KeyNotes/sections/individualKeyNote";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes
      .map((route, index) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return (
            <Route
              exact
              path={route.route}
              element={route.component}
              key={`${route.key}-${index}`}
            />
          );
        }

        return null;
      })
      .flat();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MKAlert
        color="warning"
        style={{ margin: "0", "border-radius": "0" }}
        dismissible
      >
        <p>
          All attendees must create an account (sign up) and register for the
          conference to attend the conference itself. Please visit the{" "}
          <a style={{ color: "blue" }} href="/user/registration">
            {" "}
            registration page
          </a>{" "}
          to complete your registration. If you would also like to sign up for social events, plesae visit the {" "}
          <a style={{ color: "blue" }} href="/user/register/events/checkout">
            {" "}
            social event page
          </a> after signing in.
        </p>
      </MKAlert>
      <Routes key={"routes"}>
        {getRoutes(routes)}
        <Route path="/home" element={<Home />} key="home" />
        <Route path="/signin" element={<SignInBasic />} key="signin" />
        <Route path="/signup" element={<SignUpBasic />} key="signup" />
        <Route
          path="/user/resetpassword"
          element={<ResetPassBasic />}
          key="resetpass"
        />
        <Route
          path="/user/register/tosspaymentconfirm"
          element={<ConfirmPage />}
          key="confirm"
        />
        <Route
          path="/user/register/tosspaymentconfirmevents"
          element={<ConfirmEventsPaymentPage />}
          key="confirmevents"
        />
        <Route
          path="/user/register/events/checkout"
          element={<EventRegistrationBasic />}
          key="eventconfirm"
        />
        <Route
          path="/user/sponsor/checkout"
          element={<SponsorCheckoutPage />}
          key="sponsorCheckout"
          />
          <Route
          path="/confirm/sponsorship-opportunities"
          element={<ConfirmSponsorPage />}
          key="confirmsponsor" />
        <Route path="/userinfo" element={<UserPageBasic />} key="userinfo" />
        <Route path="/travel/bus" element={<BusInstructions />} key="bus" />
        <Route path="/travel/taxi" element={<TaxiInstructions />} key="taxi" />
        <Route
          path="/travel/subway"
          element={<SubwayInstructions />}
          key="subway"
        />
        <Route path="/travel/l7hotel" element={<L7HotelInfo />} key="l7hotel" />
        <Route
          path="/travel/mercureambassadorhotel"
          element={<MercureAmbassadorHotelInfo />}
          key="mercurehotel"
        />
        <Route
          path="/pages/keynotes/:speaker"
          element={<IndividualKeyNoteTeam />}
          key="speakerkeynote"
          />
        {/* <Route
          path="/pages/callforpapers/submissions"
          element={<ProposalSubmissions />}
          key="submissions"
        /> */}
        <Route
          path="/events/socialEvent1"
          element={<SocialEvent1 />}
          key="socialevent1"
        />
        <Route
          path="/events/socialEvent2"
          element={<SocialEvent2 />}
          key="socialevent2"
        />
        <Route path="*" element={<Navigate to="/home" />} key="default" />
      </Routes>
    </ThemeProvider>
  );
}
