import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import bgImg from "assets/images/hands_theme.jpg";
import programBook from "assets/forms/2024programbook.pdf"
import scheduleGlance from "assets/forms/IACAT2024_SessionSchedule.pdf"

function ConferenceThemeBlock() {
  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({
          functions: { linearGradient, rgba },
          palette: { gradients },
        }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={12} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h4" color="white" fontWeight="bold">
            Conference Theme
          </MKTypography>
          <MKTypography variant="h1" color="white" mb={1}>
            Improving Assessment with Adaptivity and Artificial Intelligence
          </MKTypography>
          <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
            The conference theme centers on the application of adaptivity,
            artificial intelligence (AI), and other algorithms to improve
            assessment development, delivery, and implementation. Why? AI is the
            use of computers to do things that normally require a human. Before
            the advent of computers, trained psychologists delivered adaptive
            tests, and therefore computerized adaptive testing (CAT) was one of
            the first implementations of AI...{" "}
          </MKTypography>
          <Grid item xs={12} mb={1}>
            <MKTypography
              component="a"
              href="/pages/conferencetheme"
              target="_blank"
              rel="noreferrer"
              variant="body2"
              color="white"
              fontWeight="regular"
              sx={{
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Read more <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <MKTypography
              component="a"
              href={programBook}
              target="_blank"
              rel="noreferrer"
              variant="h2"
              color="white"
              fontWeight="regular"
              sx={{
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Full Conference Program Book <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <MKTypography
              component="a"
              href={scheduleGlance}
              target="_blank"
              rel="noreferrer"
              variant="h4"
              color="white"
              fontWeight="regular"
              sx={{
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Conference Schedule at a Glance <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ConferenceThemeBlock;
